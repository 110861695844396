@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Font family and rendered font appear smoother */
* {
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/* Slider margin in mobile screen */
.slick-slide>div {
	margin: 0 5px !important;
}

/* Custom scrollbar */
.custom-scrollbar {
	padding-right: 10px;
}

.custom-scrollbar::-webkit-scrollbar {
	width: 10px;
}

.custom-scrollbar::-webkit-scrollbar-track {
	background-color: #f0f0f0;
	border-radius: 5px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
	background-color: #ccc;
	border-radius: 5px;
	border: 2px solid #f0f0f0;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
	background-color: #888;
	width: 15px;
	border: 2px solid #888;
}

.custom-scrollbar::-webkit-scrollbar:hover {
	width: 15px;
}

/* Nav item animation on Hover */
.nav-item-animate-hover {
	background: linear-gradient(90deg, transparent 50%, white 50%);
	background-size: 200% 100%;
	background-position: left;
	transition: background 0.2s, color 0.2s;
	color: white;
}

.nav-item-animate-hover:hover {
	background-position: right;
	color: #003476;
}

/* Fade in animation for content */
.content-fade-in-enter {
	opacity: 0;
}

.content-fade-in-enter-active {
	opacity: 1;
	transition: opacity 400ms ease-in;
}

.content-fade-in-exit {
	opacity: 1;
}

.content-fade-in-exit-active {
	opacity: 0;
	transition: opacity 400ms ease-in;
}

.scan-region-highlight-svg {
	stroke: white !important;
}

.code-outline-highlight {
	stroke: green !important;
}

.text-overflow-ellipsis {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

button.reactour__close {
	width: 15px;
	top: 12px;
	right: 12px;
}
